<template>
<div>
  <v-dialog v-model="localDialog" max-width="380px"  transition="dialog-top-transition">
    <v-card class="pa-7">
      <v-card-title class="d-flex justify-center align-center">
        <v-row  dense>
          <v-col class="py-0 mb-1" cols="12">
                <span class="icon-background">
                  <v-icon dense color="main_red" style="font-size: 30px" class="my-0">mdi-alert-outline</v-icon>
                </span>
          </v-col>
          <v-col cols="12">
            <span>{{ $t('deleteQuestion') }}</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="text-center">{{ $t('questionTextForDelete') }}</v-card-text>
      <v-card-actions class="mb-2">
        <v-row dense>
          <v-col cols="12">
            <v-btn block depressed color="red" class="text-color-white" @click="deleteOrganizationConfirm">{{ $t('deleteField') }}</v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn  outlined depressed color="main_black" block @click="closeDeleteDialog">{{ $t("cancel") }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import { showErrorAlert, showSuccessAlert } from '@/utils/utilities';

export default {
  props: ['dialog', 'organizationId'],
  emits: [],
  name: 'DeleteOrganizationDialog.vue',
  data () {
    return {
      loading: false,
      localDialog: this.dialog,
      localOrganizationId: this.organizationId,
    };
  },
  methods: {
    async getMedicalInstitutions () {
      this.loading = true;
      await this.$store
          .dispatch('medicalInstitutions/getMedicalInstitutions')
          .then(() => {
            // this.medicalInstitutions = this.medicalInstitutionsFromRepo;
            this.loading = false;
          });
    },
    async deleteOrganizationConfirm () {
      await this.$store.dispatch('medicalInstitutions/deleteMedicalInstitution', this.localOrganizationId)
          .then(res => {
            if (res.data.resFlag) {
              showSuccessAlert(this.$t('success-operation'));
              this.getMedicalInstitutions();
              this.closeDeleteDialog();
            } else {
              showErrorAlert(this.$t('failed-operation'));
            }
          });
    },
    closeDeleteDialog () {
      this.localDialog = false;
      this.localOrganizationId = -1;
    },
  },
  watch: {
    dialog (val) {
      this.localDialog = val;
    },
    organizationId (val) {
      this.localOrganizationId = val;
    },
    localDialog (val) {
      this.$emit('update:dialog', val);
    },
    localOrganizationId (val) {
      this.$emit('update:organizationId', val);
    },
  },
};
</script>
